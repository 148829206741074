.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    background-color: white;
    overflow: auto;
  }
  
  .modal-header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .modal-close-button {
    padding: 4px;
    border-radius: 50%;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-close-button:hover {
    background-color: #f5f5f5;
  }
  
  .modal-content {
    padding: 16px;
  }
  
  