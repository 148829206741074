.protocol-container {
  width: 100%;
}

.protocol-header {
  background-color: #ecc193;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px;
}

.protocol-title {
  font-weight: 700;
  text-align: center;
  font-size: 16px;
}

.protocol-table {
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.protocol-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
}

.protocol-row:last-child {
  border-bottom: none;
}

.protocol-category {
  padding: 16px;
  font-weight: 600;
  background-color: white;
  font-size: 14px;
  width: 25%;
}

.protocol-details {
  padding: 16px;
  background-color: white;
  font-size: 14px;
  width: 75%;
  border-left: 1px solid #e0e0e0;
  line-height: 1.5;
}

/* Mobile Styles */
@media (max-width: 576px) {
  .protocol-container {
    width: 95%;
    margin: 0 auto;
  }

  .protocol-header {
    padding: 8px;
  }

  .protocol-title {
    font-size: 16px;
  }

  .protocol-table {
    font-size: 12px;
  }

  .protocol-row {
    flex-direction: column;
  }

  .protocol-category {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
  }

  .protocol-details {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border-left: none;
  }
}
