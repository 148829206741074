/* Popup Overlay - covers the entire screen */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
  }
  
  /* Popup Container */
  .popup-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    animation: slideIn 0.3s ease-out;
    overflow: hidden;
  }
  
  /* Popup Header */
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #eaeaea;
    background-color: #f8f9fa;
  }
  
  .popup-title {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
    font-weight: 600;
  }
  
  .popup-close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
    margin-left: auto;
  }
  
  .popup-close-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #333;
  }
  
  /* Popup Content */
  .popup-content {
    overflow-y: auto;
    flex: 1;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .popup-container {
      width: 95%;
      max-height: 95vh;
    }
  
    .popup-header {
      padding: 12px 16px;
    }

  }
  
  