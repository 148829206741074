:root {
  --appolloGreen: #196983;
  --backGround: #fff4f4;
}
#PDPageContainer * {
  margin: 0;
  padding: 0%;
  box-sizing: border-box;
}
#root {
  font-size: 11px;
}
#navBar_PDPage {
  width: 100%;
  height: 10em;
  padding: 0 5em 1em 1em;
  max-height: 7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 11;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.outframe-heading-bar-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 7em;
  height: 100vh;
}

#ApolloLogoBox_PDPage {
  height: 100%;
}
#ApolloLogoBox_PDPage > img {
  height: 100%;
}
#titleNameOfApplication_PDPage {
  font-size: 2.5em;
  margin: auto;
}

#LogoOutButton_PDPage {
  font-size: 1.1em;
  font-weight: 500;
  padding: 0.7em 0.9em 0.7em 0.9em;
  border-radius: 10px;
  border: 0;
  box-shadow: 0.2em 0.2em 0.2em #909090;
  /* background-color: var(--appolloGreen); */
  color: white;
  margin-left: 20px;
}

.navigation-buttons {
  display: flex;
}
button {
  cursor: pointer;
}
#contentContainer {
  width: fit-content;
  height: fit-content;
  background-color: white;
  margin-top: 1em;
  border-radius: 30px;
}

.nav-document-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  top: -80px;
}
.doc-link-text {
  color: black;
  text-decoration: none;
  font-size: 13px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doc-icon {
  width: 40px;
}
.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 500px;
  position: relative;
  top: 10%;
}
.each-step-container {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.circle {
  width: 30px;
  height: 30px;
  background-color: transparent;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin-left: 10px;
}
.step-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 15px;
}
.check-img {
  width: 50%;
}

.circle-active {
  background-color: white;
  color: black;
}

.text-active {
  color: white;
}

.circle-completed {
  background-color: white;
  color: black;
}

.text-completed {
  color: white;
}

.outframe-home-logout-button-container {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-left: 1em;
}
.outframe-logout-button {
  border: none;
  outline: none;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
  margin-left: 30px;
}

@media (max-width: 1080px) {
  #contentContainer {
    width: 100%;
  }
}
#bodyContentContainer {
  display: flex;
  width: 100%;
  display: flex;
  background-color: white;
  margin: auto;
}
#leftColorBlock {
  width: 15%;
  max-width: 170px;
  flex: 1;
  position: fixed;
  box-shadow: 6px 0px 9px rgb(0 0 0 / 20%);
  height: 100vh;
}

.leftborder-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  font-weight: 600;
  font-size: 16px;

  /* top: 45%; */
}

.hightlightContent {
  background-color: white;
  /* color: var(--appolloGreen); */
  border-radius: 5px;
  padding: 15px;
  margin: 0;
}
#rightContentBlock {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-left: 7em;
}

#rightContentBlock > * {
  width: 100%;
  max-width: 900px;
}
.InputForm {
  display: grid;
  grid-row: auto;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
}
.nextButton {
  font-size: 16px;
  font-weight: 500;
  padding: 0.3em 0.9em 0.3em 0.9em;
  border-radius: 5px;
  border: 0;
  color: white;
  padding: 10px 30px 10px 30px;
}
.ActualForm {
  display: flex;
  width: min-content;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#bodyContentContainer {
  background-color: "white";
}
@media (max-width: 1100px) {
  #leftColorBlock {
    display: none;
  }
}
.boxContainingRadioAndLable {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 900px) {
  .ActualForm {
    width: auto;
  }
  .InputForm {
    display: flex;
    flex-direction: column;
  }
  .InputFormInAttributePage {
    width: 100%;
    padding: 1.5em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 2px;
  }
  .InputForm {
    padding: 1.5em 0 1.5em 0;
  }
  #leftColorBlock {
    display: none;
  }
  #rightContentBlock {
    width: 80%;
  }
  #contentContainer {
    width: 100%;
  }
}
@media (max-width: 900px) {
  #bodyContentContainer {
    font-size: 11px;
  }
  #navBar_PDPage {
    padding-right: 1em;
  }
}
@media (max-width: 560px) {
  #navBar_PDPage {
    font-size: 11px;
    padding:10px;
    height:5em
  }
  #bodyContentContainer {
    font-size: 11px;
  }
  /* .app-landing-page-icon-container {
    width: 30px;
  } */
  #ApolloLogoBox_PDPage > img {
    height: 70%;
  }
}
@media (max-width: 560px) {
  #bodyContentContainer {
    font-size: 11px;
  }
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .nav-link-buttons {
    display: none;
  }
  .logout-button{
    display: none;
  }

  /* .app-landing-page-icon-container {
    padding: 10px;
  } */

  .landingpage-action-icons {
    margin: 10px 0;
  }

  .outframe-heading-bar-container {
    width: 5em;
  }

  #rightContentBlock {
    padding-left: 5em;
  }

  #navBar_PDPage {
    padding: 0 1em;
  }

  #titleNameOfApplication_PDPage {
    font-size: 1.5em;
  }

  .logout-button-container-block {
    flex-direction: column;
    align-items: center;
  }

  .logout-button {
    margin: 5px 0;
  }
}
