/* Main container */
.medical-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
}

/* Loader */
.loader-container-progress {
  margin-bottom: 10px;
  height: 20px;
}

.loader {
  height: 8px;
  width: 100%;
  /* background-color: #f5f5f5; */
  border-radius: 10px;
  overflow: hidden;
}

.loader-progress {
  height: 100%;
  background-color: #bbb6f9;
  border-radius: 10px;
  transition: width 0.3s ease;
}

/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #bbb6f9;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Tabs */
.tabs {
  display: flex;
  border: 1px solid #c7c4c4;
  border-radius: 12px;
  margin-bottom: 10px;
  background: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.tab {
  flex: 1;
  padding: 12px 16px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #9c9999;
  transition: all 0.3s ease;
  border-radius: 8px;
  gap: 10px; /* Adds space between icon and text */
}

.tab.active {
  color: #000;
  font-weight: 500;
  background-color: white;
  /* box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); */
}

.tab:hover:not(.active) {
  background-color: #f9fafb;
  color: #4b5563;
}

.tab-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
}

/* Icons */
.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* Section styling */
.section {
  margin-bottom: 30px;
}

.section-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.form-grid,
.lifestyle-form-grid,
.medical-form-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  /* margin-bottom: 2em; */
}

.attribute-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.gender-select {
  border: none;
  outline: none;
  width: 90%;
  font-size: 14px;
  background: transparent;
  padding:4.5px;
}

.form-field {
  display: flex;
  align-items: center;
  border: 1px solid #c7c4c4;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  transition: all 0.3s ease;
  background: white;
  gap: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-field input {
  border: none;
  outline: none;
  width: 90%;
  font-size: 14px;
  background: transparent;
  padding: 4px 0;
}

.form-field label {
  position: absolute;
  left: 36px; /* Adjusted to align properly */
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  transition: all 0.3s ease;
  pointer-events: none;
  color: #3b393e;
  font-size: 14px;
}

/* Move label up when input is focused or has a value */
.form-field input:focus + label,
.form-field input:not(:placeholder-shown) + label {
  top: 0;
  left: 30px;
  font-size: 12px;
  padding: 0 8px;
  border-radius: 4px;
  color: #555;
  background: white;
}

.form-field input::placeholder {
  color: #666;
  font-size: 12px;
}

.form-field input:focus::placeholder {
  opacity: 1;
}

/* life */

.form-field input.readonly:focus {
  outline: none;
  border-color: #ccc;
}
.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button {
  padding: 12px 24px;
  background-color: rgb(103, 84, 150);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: rgb(123, 104, 171);
}

.submit-button:disabled {
  background-color: #c7c4c4;
  cursor: not-allowed;
}

.popup-main-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.warring-popup-container {
  min-width: 250px;
  max-width: 600px;
  min-height: 220px;

  border-radius: 8px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;
}
.warning-icon {
  width: 30px;
}
.warring-popup-content-heading {
  font-size: 20px;
  color: red;
  text-align: center;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  user-select: none;
}
.popup-text-highlight-text {
  font-size: 20px;
  text-align: center;
  user-select: none;
}
.warring-button {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  padding: 14px 30px;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: rgb(103, 84, 150);
  color: white;
  font-weight: 600;
  margin: 15px;
  font-size: 14px;
}

.green-text {
  color: rgb(0, 153, 0);
}
.red-text {
  color: red;
}


.app-landing-page-icon-container {
  width: 60px;
  height: 20px;
  padding: 5px;
  border-radius: 24px;
  text-align: center;
  /* margin: 5px; */
}

.all-fields-warning-msg {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .submit-button {
    width: 85%;
    padding: 12px;
  }
  .form-sections {
    padding-left: 20px;
  }
  .app-landing-page-icon-container {
    width: 40px;
  }
}

/* Add these new styles */
.required {
  color: #000;
  margin-left: 4px;
}

.error {
  border-color: #ff0000;
}

.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.warning-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.warning-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
}

.warning-content h3 {
  color: #ff9900;
  margin-bottom: 10px;
}

.warning-content button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.warning-content button:hover {
  background-color: #0056b3;
}
.field-icon {
  color: rgb(77, 75, 75);
}

/* Medical cards */
.medical-card {
  border: 1px solid #c7c4c4;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
}

.card-header {
  display: flex;
  align-items: center;
  padding: 15px;
}
.card-title {
  font-weight: 500;
  margin-right: 5px;
}

/* Dropdown */
.dropdown {
  margin: 0 15px 15px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c7c4c4;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}

/* Checkbox styling */
.checkbox-group {
  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.nested-checkboxes {
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"].checkbox-blue {
  accent-color: #3646d9;
}

.checkbox-label span {
  font-size: 14px;
}

.patient-medical-info-heading {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #333;
  border-left: 5px solid rgb(103, 84, 150);
  padding-left: 10px;
  border-radius: 5px 0px 0px 5px;
}

.basic-info,
.physical-attributes,
.lifestyle,
.medical-history,
.lab-history {
  margin-bottom: 40px;
}

.card,
.lifestyle-card {
  border: 1px solid #c7c4c4; /* Lighter border for a clean look */
  border-radius: 12px; /* Slightly rounded corners */
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); /* Light shadow for depth */
  margin-bottom: 15px;
}

.card p,
.lifestyle-card p {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
}

.lifestyle-card {
  margin-bottom: 0px;
}

.radio-group,
.select-group {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Adds spacing between select and sub-option */
}

.radio-group select {
  width: 60%; /* Reduces the width of the select box */
  padding: 8px;
  border: 1px solid #c7c4c4;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  appearance: none; /* Hides the default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' fill='%23000000'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjusts space for the icon */
  background-size: 20px;
}

.select-group select {
  width: 40%; /* Reduces the width of the select box */
  padding: 8px;
  border: 1px solid #c7c4c4;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  appearance: none; /* Hides the default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' fill='%23000000'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjusts space for the icon */
  background-size: 20px;
}

.radio-group select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: none;
}

.sub-option label,
.sub-option-label {
  font-size: 14px;
  font-weight: 500;
}

.sub-option-radio {
  display: flex;
  align-items: center;
  margin-left: -20px;
}

.sub-option-radio-label {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.sub-option input[type="number"] {
  width: 60%;
  padding: 10px;
  border: 1px solid #c7c4c4;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
  outline: none;
}

.fatty-liver-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.warning-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 8px;
}

.warning-content {
  text-align: center;
}

.warning-content h3 {
  color: red;
}

.warning-content button {
  margin-top: 10px;
  padding: 10px;
  background: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.sub-option-wrapper {
  display: flex;
  gap: 20px; /* Space between inputs */
  align-items: center;
  margin-bottom: 10px;
}

.number-input-container {
  display: flex;
  flex-direction: column;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
}

.date-picker-container input {
  padding: 10px;
  border: 1px solid #c7c4c4;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.date-picker-container input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

.input-date-container {
  display: flex;
  gap: 10px;
}

.number-input-container,
.date-picker-container {
  flex: 1;
  height: 100%; /* Ensure both elements have the same height */
}

.sub-option-input {
  width: 35%;
  padding: 10px;
  border: 1px solid #c7c4c4;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
  outline: none;
}

/* Description button */
.description-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
}

/* Description overlay */
.description-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  padding: 20px;
}

.description-overlay.open {
  transform: translateX(0);
}

/* Close button */
.description-overlay .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Description content */
.description-content {
  margin-top: 40px;
}

.description-content h3 {
  margin-bottom: 10px;
}

.description-content p {
  margin-bottom: 10px;
}

.description-content ul {
  padding-left: 20px;
  margin-bottom: 10px;
}

.description-content li {
  margin-bottom: 5px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .description-overlay {
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }

  .description-overlay.open {
    transform: translateY(0);
  }

  .popup-main-container {
    top: 40px;
    padding: 0px;
  }

  .warring-popup-container {
    min-width: 150px;
    max-width: 250px;
  }
}

@media (max-width: 640px) {
  .medical-form {
    padding: 10px;
  }
  .select-group select {
    width: 60%;
  }
  .checkbox_input_box_in_input_component {
    margin-bottom: 10px;
  }

  .fatty-liver-section {
    margin-bottom: 10px;
  }
  .patient-medical-info-heading {
    font-size: 1rem;
  }
}

@media (min-width: 640px) {
  .form-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .medical-form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .lifestyle-form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .form-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .lifestyle-form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .medical-form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}


.loader-container {
  position: absolute;
  top: 30%;
  right: 50%;
  height: min-content;
  width: min-content;
}

.checkbox_input_box_in_input_component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.checkbox_input_box_in_input_component .lable_in_input_component {
  font-size: 14px;
  margin: 0 0 0 0;
}
.checkbox_lable_box_input_in_input_component {
  width: fit-content;
  margin: -6px 0 0 10px;
}

.inputbox p {
  font-size: 1em;
  margin-left: 2px;
}
.inputbox > div > label {
  font-size: 16px;
}
.inputbox > label {
  font-size: 16px;
}

.sub-option-container {
  margin-left: 2em;
}
.sub-option-container .checkbox_lable_box_input_in_input_component {
  padding-left: 0;
}

.checkbox_lable_in_input_component {
  font-weight: 400 !important;
}



