.PopUpForPaitentConcent {
  width: 60em;
  max-width: 100%;
  background-color: white;
  height: 70vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  padding: 1em 2em 0.5em 2em;
  margin-bottom: 2em;
}
.showConcentButton {
  padding: 0.2em 0.4em;
  outline: none;
  border: 0;
  font-size: 16px;
  margin-top: 2px;
  background-color: transparent;
}
.showConcentDiv {
  width: fit-content;
  margin-right: auto;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
}
.DocLogoForConcentButton {
  font-size: 1.5em;
}

.checkBoxForTC {
  display: flex;
  flex-direction: column;
}

.checkbox-for-patient-consent {
  display: flex;
  align-items: center;
}

.patient-consent-label-content {
  margin-left: 15px;
  font-size: 14px;
}
.checkBoxForTC label {
  font-weight: 400 !important;
}

.form-validation-error-text {
  color: red;
  font-size: 14px;
  margin: 15px;
}

.patient-checkbox-with-text {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.main-heading-with-icon-container {
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.main-heading {
  font-size: 16px;
  margin: 0px;
  color: white;
}

@media (max-width: 900px) {

  .showConcentButton {
    font-size: 16px;
    text-decoration: underline;
    width: 90%;
    text-align: left;
  }
}

.consent-icon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
