#Container {
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 7%;
  background-color: #f8f1f6;
  height: 100%;
}
.contentBoxs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
}
#logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
#logo > img {
  width: 70px;
  height: 60px;
}
#NavItemsContainer {
  display: flex;
  flex-direction: column;
  list-style: none;
  position: relative;
  top: 2%;
}
#NavItemsContainer::-webkit-scrollbar {
  display: none;
}

.logoff-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  cursor: pointer;
  position:absolute;
  bottom:30px;
}

.logoff {
  border: none;
  outline: none;
  background-color: var(--ApolloGreen);
  color: white;
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
}

.nav-action-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.NavItems {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 5px;
  text-wrap: wrap;
  text-align: center;
  margin-top: 5px;
}
#ActiveNavItem {
  background-color: #dcdaf5;
}
.upperContent {
  width: 100%;
  display: flex;
  height: auto;
}

.nav-icon-landing {
  width: 20px;
  height: 20px;
  fill: black;
}

.icon-container {
  width: 60px;
  height: 20px;
  padding: 5px;
  border-radius: 24px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px){
  .NavItems{
    display: none;
  }
  .icon-container{
    width: 40px;
  }
  #Container{
    width: 11%;

  }
  #logo > img{
    width: 45px;
    height: 45px;
  }

  .logoff-button{
    margin-left:-10px;
  }
}


