.login-page-container {
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label-with-input-container {
  margin-bottom: 20px;
}

.logo-with-input-continaer {
  position: relative;
}

.login-form-container {
  position: relative;
  border: 1px solid #c7c4c4;
  border-radius: 8px;
  width: 300px;
  height: 370px;
  background-color: #ffffff;
  padding: 50px;
}

.login-heading {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
  color: rgb(123, 104, 171);
}

.logo-with-input-continaer {
  position: relative;
  background: #f2f2f2;
  border-radius: 4px;
  width: 100%;
  border: 0;
  margin: 15px 0;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.input-bar {
  outline: 0;
  background: #f2f2f2;
  width: 85%;
  height: 100%;
  border: 0;
  font-size: 16px;
  position: absolute;
  right: 0px;
}

.input-label {
  font-size: 16px;
  font-weight: 600;
}

.logo-with-input-continaer:hover {
  box-shadow: 0 0 5px 0 rgb(133, 115, 180);
}

.login-below-button:hover {
  color: rgb(133, 115, 180);
}

.login-button {
  outline: 0;
  background-color: rgb(123, 104, 171);
  width: 100%;
  border: 0;
  margin-top: 10px;
  border-radius: 3px;
  padding: 15px;
  color: #ffffff;
  font-size: 16px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.login-button:hover,
.login-button:active,
.login-button:focus {
  background: rgb(133, 115, 180);
  color: #fff;
}

.error-text {
  color: red;
  /* text-align: center; */
  margin: 0px;
  margin-left: 10px;
  font-size: 14px;
}

.password-img {
  position: absolute;
  width: 30px;
  height: 20px;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

.valid-page {
  color: red;
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.auth-icons {
  width: 20px;
  height: 20px;
  fill: rgb(123, 104, 171);
  position: absolute;
  width: 30px;
  height: 20px;
  top: 15px;
  left: 10px;
  cursor: pointer;
}

.login-loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-with-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-user-text {
  font-size: 13px;
  margin-top: 10px;
  text-align: right;
}
.new-user-link-tag {
  color: red;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  background-color: transparent;
  border: none;
  outline: none;
}
.checkbox-text {
  font-size: 13px;
  margin-left: 20px;
  cursor: pointer;
  user-select: none;
}

.take-me-to-app {
  display: flex;
  flex-direction: column;
}
