#consentForm {
  position: relative;

  height: auto;
  max-height: 70vh; 
  overflow-y: auto;
  background-color: transparent; 
  padding: 0px 20px;
  font-size: 14px;
  cursor: auto;
  line-height: 1.6;
}

/* Section Container */
.section-container {
  margin-bottom: 15px;
}

/* Heading Levels - Consistent Styling */
.heading {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #333;
}

.heading-level-1 {
  font-size: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.heading-level-2 {
  font-size: 18px;
}

.heading-level-3 {
  font-size: 16px;
}

.heading-level-4 {
  font-size: 14px;
}

.heading-level-5 {
  font-size: 12px;
}

.heading-level-6 {
  font-size: 10px;
}

/* Paragraph Styling */
.consent-paragraph {
  font-size: 14px;
  margin: 8px 0;
  line-height: 1.8;
  color: #333;
}

/* List Styles */
.consent-list {
  list-style: decimal;
  margin-left: 20px;
  padding-left: 10px;
  margin-bottom: 15px;
}

.consent-list-item {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 5px;
  color: #333;
}

/* Notes / Blockquotes */
.consent-note {
  font-size: 14px;
  font-style: italic;
  padding: 10px;
  background-color: #f4f4f4;
  border-left: 5px solid #007bff;
  margin: 10px 0;
  color: #555;
}

/* Mobile View */
@media (max-width: 768px) {
  #consentForm {
    font-size: 12px;
    padding: 15px;
    max-height: 65vh;
  }

  .heading-level-1 {
    font-size: 18px;
  }

  .heading-level-2 {
    font-size: 16px;
  }

  .heading-level-3 {
    font-size: 14px;
  }

  .consent-paragraph {
    font-size: 12px;
    line-height: 1.6;
  }

  .consent-list {
    padding-left: 5px;
    margin-left: 15px;
  }

  .consent-list-item {
    font-size: 12px;
  }

  .consent-note {
    font-size: 12px;
    padding: 8px;
  }
}

/* Smaller screens */
@media (max-width: 480px) {
  #consentForm {
    padding: 10px;
    max-height: 60vh;
  }

  .heading-level-1 {
    font-size: 16px;
  }

  .heading-level-2 {
    font-size: 14px;
  }
}