
.new-report-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 16px;
}

.new-report-card {
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  margin-bottom: 24px;
}

.report-card-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.risk-section {
  background-color: #eceff1;
  border-radius: 8px;
  padding: 20px;
  background-color: #6695a3;
  border: 1px solid #6695a3;
}

.risk-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-bottom: 16px;
  justify-content: center; /* Centers items horizontally */
}

.risk-card {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.risk-card-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.risk-value {
  font-size: 14px;
  font-weight: 500;
}

.risk-highlight {
  color: white;
  text-align: center;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 14px;
}

.risk-medium-color {
  background-color: #dc7c16 !important;
}

.risk-low-color {
  background-color: rgb(0, 146, 0) !important;
}

.risk-high-color {
  background-color: rgb(255, 81, 50) !important;
}

.chart-section {
  background-color: #eceff1;
  border-radius: 8px;
  padding: 20px;
}

.note-items {
  font-size: 12px;
  color: white;
  line-height: 2;
}

.shap-main-plot {
  width: 100%;
  height: 100%;
}

/* New styles for the contributing factor button */
.contributing-factor-button {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #6695a3;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.contributing-factor-button:hover {
  background-color: #557b87;
}

.button-icon {
  margin-right: 8px;
}

@media (min-width: 576px) {
  .risk-cards {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Makes it flexible */
    justify-content: center; /* Ensures centering */
  }


  .report-card-title {
    font-size: 20px;
  }
  .risk-card-title {
    font-size: 16px;
  }

  .risk-value {
    font-size: 14px;
  }

  .risk-highlight {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .risk-cards {
    grid-template-columns: repeat(4, 1fr);
  }

  .report-card-title {
    font-size: 20px;
  }
  .risk-card-title {
    font-size: 16px;
  }

  .risk-value {
    font-size: 14px;
  }

  .risk-highlight {
    font-size: 14px;
  }
}

.risk-cards:has(.card:nth-child(2)) {
  display: flex;
  justify-content: center;
}
