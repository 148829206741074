/* General styles */
* {
  margin: 0;
  padding: 0;
}

.imageContainer > img {
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer-block {
  flex-direction: column;
  padding-bottom: 40px;
}

.app-info-landing-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: white;
  margin: auto;
}

.apollo-image-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  width: 100%;
}

.main-inner-container {
  width: 90%;
  margin-left: 7%;
}

.main-section-inner-block {
  margin-left: 4%;
}

.heading-bar-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 7%;
  height: 100%;
}

.apollo-image {
  width: 70px;
  height: 60px;
}

.landingpage-action-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7px;
  cursor: pointer;
  margin-bottom: 7px;
}

.icon-landing {
  width: 18px;
  height: 18px;
}

.nav-link-buttons {
  margin-top: 5px;
  font-size: 12px;
}

.app-landing-page-icon-container:hover {
  background-color: #e8e0e8;
}

.list-of-redirect-elements {
  display: flex;
  flex-direction: column;
  list-style: none;
  position: relative;
  top: 2%;
}

.list-of-redirect-elements > li {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-wrap: wrap;
}

.logout-button-container-block {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 80px;
}

.logout-button {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  margin-top: 5px;
}

.about-us-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.each-individual-section {
  display: flex;
  flex-direction: column;
  margin: 60px 0px;
}

.section-block-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.home-page-content-container {
  width: 38%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}

.heading-apollo-text {
  font-size: 46px;
  color: #5fa8d3;
  font-weight: 500;
  text-align: center;
}

.home-page-image-container {
  width: 60%;
}

.copd-home-page-image {
  border-radius: 30px;
  width: 100%;
  height: 500px;
}

.about-us-container {
  height: 60px;
  padding: 0 30px;
  transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
  border: 0;
  border-radius: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  cursor: pointer;
  margin-top: 25px;
}

.landing-content-container {
  width: 50%;
  padding-right: 20px;
}

.image-container-block {
  position: sticky;
  top: 20px;
  display: flex;
  align-items: center;
  width: 40%;
}

.cerficate-image-container-block{
  /* position: sticky; */
  top: 20px;
  display: flex;
  align-items: center;
  width: 45%;
}

.cerficate-intepretation-container{
  width: 100%;
  overflow-x: auto;

}

.imterpretation-image-container-block {
  position: relative;
  top: 0;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.why-image {
  width: 350px;
  height: 200px;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.image-1-block {
  position: absolute;
  top: 45%;
  z-index: 1;
  margin: -40px;
}

.image-container-block .why-image-container:last-child {
  position: relative;
  z-index: 0;
  top: -30%;
  margin-left: auto;
}

.landing-list {
  list-style: none;
  line-height: 2;
  border-radius: 20px;
  padding: 20px;
}

.landing-list > li,
.link-text {
  font-size: 16px;
  padding: 3px 15px 3px 0px;
  margin: 10px 0px 10px 0px;
  border-radius: 30px;
  text-align: justify;
}

.link-point-img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.landing-list-point {
  border-radius: 30px;
  text-align: justify;
}

.each-section-heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sub-points-list-container {
  list-style: none;
  margin-left: 20px;
}

.section-3-list {
  font-size: 16px;
  list-style: none;
}

.section-3-list > li {
  padding: 3px 15px 3px 0px;
  margin: 10px 0px 10px 0px;
  border-radius: 30px;
  text-align: justify;
}

.section3-point {
  font-size: 16px;
  padding: 3px 15px 3px 0px;
  border-radius: 30px;
  text-align: justify;
}

.inner-sub-heading {
  font-size: 16px;
}

.intepretation-image {
  width: 100%;
  /* height: 510px; */
  /* max-height: 100vh; */
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.cerficate-image {
  height: 510px;
  /* width: 400px; */
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


.certificate-note-point {
  font-size: 16px;
  font-weight: 500;
}

.checkbox-container {
  position: relative;
  margin-top: 10px;
}

.checkbox-input {
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 0;
}

.custom-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 2px solid #d9d5d5;
  pointer-events: none;
  background-image: linear-gradient(white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -30%;
}

.custom-checkbox_border_radius {
  border-radius: 50%;
}

.checkbox-input:checked ~ .custom-checkbox {
  background-color: rgb(103, 84, 150);
  border: 2px solid white;
}

.margin-top-10 {
  margin-top: 10px;
  margin-left: 20px;
}

.align-flex-start {
  align-items: flex-start;
}

.black-color {
  color: black;
}

.d-none {
  display: none;
}

.app-popup-main-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.app-popup-container {
  background-color: white;
  width: 90%;
  height: 100% !important;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
}
.app-popup-content-heading {
  font-size: 26px;
  font-weight: 600;
  padding: 20px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.app-popup-content-container {
  flex-grow: 1;
  overflow: auto;

  padding: 20px;
}
.app-popup-close-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.app-popup-close-button {
  padding: 8px 16px;
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;

  transition: all 200ms ease-in-out;
  color: rgb(29, 31, 29);
}
.app-popup-close-button:hover {
  background-color: rgb(214, 214, 214);
}
.app-popup-text-highlight-text {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
}
.app-popup-list-container {
  list-style-type: number;
  padding-left: 20px;
}
.app-popup-list-container li {
  font-size: 13px;
  margin-bottom: 10px;
}
.app-popup-text {
  font-size: 13px;
  margin-bottom: 12px;
}

.app-loading-container {
  width: 100%;
  height: 100vh;
}

.landing-navigation-buttons {
  display: flex;
  height: 40px;
  margin-top: 20px;
}

.footer-button-container {
  display: flex;
  width: 25%;
  justify-content: space-around;
  margin: 20px;
}
.privacy-button {
  background-color: transparent;
  color: white;
  text-decoration: dashed;
  border: none;
  outline: none;
  margin-right: 10px;
  font-size: 16px;
}
.bg-footer-brown {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.footer-click {
  color: black;
  text-decoration: none;
  font-size: 16px;
}
.footer-click:hover {
  background-color: transparent;
  color: #5fa8d3;
}

#TakeToMeApp {
  background-color: rgb(103, 84, 150);
  margin-top: 1em;
  padding: 20px;
  font-size: 16px;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-bottom: 3em;
}
#footer {
  color: black;
  font-size: 16px;
  padding-top: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-border {
  border: 1px solid #d6d3d3;
  border-radius: 8px;
  padding:20px;
}
/* Mobile view adjustments */
@media (max-width: 768px) {
  .heading-bar-container {
    width: 11%;
  }

  .list-of-redirect-elements {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list-of-redirect-elements > li {
    display: none;
  }

  .home-page-content-container,
  .home-page-image-container {
    width: 95%;
  }

  .home-page-content-container {
    border-radius: 0px 0px 20px 20px;
    padding-bottom: 20px;
  }

  .home-page-image-container {
    order: -1;
  }

  .logout-button-container-block {
    position: fixed;
    bottom: 10px;
    /* flex-direction: row; */
  }

  .logout-button {
    font-size: 14px;
    margin: 0 10px;
  }

  .landing-content-container,
  .image-container-block,
  .imterpretation-image-container-block,.cerficate-image-container-block {
    width: 100%;
  }

  .image-1-block {
    position: relative;
    top: 0;
    margin: 0;
  }

  .image-container-block .why-image-container:last-child {
    position: relative;
    top: 0;
    margin: 0;
  }

  .each-individual-section {
    margin: 40px 0px;
  }

  .section-block-container {
    flex-direction: column;
  }

  .landing-content-container {
    order: 2;
  }

  .image-container-block {
    order: 1;
    position: relative;
  }

  .main-container {
    width: 90%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  .copd-home-page-image {
    border-radius: 0px;
    width: 100%;
    height: 300px;
    border-radius: 20px 20px 0px 0px;
  }
  .each-section-heading {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .landing-list > li,
  .link-text {
    font-size: 16px;
    padding: 3px 3px 3px 0px;
    margin: 5px 0px 5px 0px;
  }

  .main-inner-container {
    width: 95%;
    margin-left: 10%;
  }

  .apollo-image {
    width: 45px;
    height: 45px;
  }
  .imterpretation-image-container-block {
    position: relative;
    margin-bottom: 20px;
  }

  .cerficate-image {
    height: 300px;
    /* width: 100%; */
    margin-bottom: 40px;
  }

  .why-image {
    width: 250px; /* Smaller width for mobile */
    height: 150px; /* Smaller height for mobile */
    border-radius: 10px;
  }

  .image-1-block {
    position: absolute;
    top: 40%; /* Adjust positioning */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    margin: -20px;
  }

  .image-container-block .why-image-container:last-child {
    position: relative;
    z-index: 1;
    top: -15%;
    margin-left: auto;
  }

  .mobile-view-image-container {
    margin-bottom: 80px;
  }
  .footer-block {
    width: 100vw;
    margin: auto;
  }
  #footer {
    color: black;
    font-size: 16px;
    padding-top: 2em;
    width: 100vw;
    display: flex;
  }

  .footer-inside-element {
    margin-left: 45px;
  }
}

@media (max-width: 100px) {
  .logout-button-container-block {
    bottom: 30px;
    margin-left: 1em;
  }
}

@media (max-width: 1000px) {
  .logout-button-container-block {
    bottom: 30px;
    margin-left: 10px;
  }
}
