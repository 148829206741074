.lifestyle-aicvd-form-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  
  .aicvd-radio-options{
      margin-bottom:10px;
  }
  
  @media (max-width: 640px) {
    .lifestyle-aicvd-form-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .lifestyle-aicvd-form-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }