.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page {
  width: 210mm;
  height: 295mm;
  /* position: relative;
  display: flex;
  flex-direction: column; */
}
.page-header {
  width: 99%;
  background-color: #17748f;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px;
}

.header-heading {
  font-size: 24px;
  color: white;
  font-family: "Roboto", sans-serif;
}
.header-log {
  width: 80px;
}
.pdf-user-info-container {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  height: 50px;
  padding: 5px 15px;

  border-left: 5px solid #f2b12a;
  margin-bottom: 15px;
}
.pdf-user-info-container li {
  width: 33%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0%;
}
.pdf-user-info-heading {
  font-size: 14px;
  font-weight: 600;
  width: 70px;
  font-family: "Roboto", sans-serif;
  margin: 0px;
}
.pdf-user-info-value {
  font-size: 14px;
  margin: 0px;
  margin-left: 8px;
  color: #17748f;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}

.line {
  width: 97%;
  height: 1px;
  background-color: rgb(156, 156, 156);
  margin-bottom: 15px;
}
.pdf-sub-heading {
  font-size: 14px;
  margin-bottom: 8px;
  color: #17748f;
  font-family: "Roboto", sans-serif;
}
.pdf-page-text {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}

/* vitals */

.details-container {
  border: 2px solid #ff4b23;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.details-header {
  background-color: #ff4b23;
  color: white;
  padding: 10px;
  margin: -20px -20px 10px -20px;
  font-size: 14px;
  font-weight: bold;
  width: 30%;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 30px;
  list-style: none;
  padding: 0;
}

.details-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-key {
  font-weight: bold;
  color: black;
  font-size: 13px;
}

.details-value {
  border: 1px solid #ff4b23;
  padding: 5px;
  min-width: 100px;
  text-align: center;
  border-radius: 4px;
  font-size: 13px;
  height: 25px; /* Ensures uniform height */
  overflow: auto; /* Enables scrolling for overflow */
}

/* heart health */

.heart-health-container {
  border: 2px solid #17748f;
}

.heart-health-header {
  background-color: #17748f;
}
.heart-health-value {
  border: 1px solid #17748f;
}

.medical-container {
  border: 2px solid #167c69;
}

.medical-header {
  background-color: #167c69;
}
.medical-value {
  border: 1px solid #167c69;
}

/* footer style */

.footer-text {
  font-size: 11px;
  font-family: "Roboto", sans-serif;
}
.footer-container {
  margin-top: auto;
}
.line-1 {
  background-color: #17748f;
  height: inherit;
  width: 60%;
}
.line-2 {
  background-color: #f2b12a;
  height: inherit;
  width: 40%;
}
.d-flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.justify-space-between {
  justify-content: space-between;
}
.style-line-container {
  margin-top: 6px;

  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
}
.plr-25 {
  padding: 0px 25px;
}
.alingn-item-end {
  align-items: flex-end;
}
.mb-4 {
  margin-bottom: 4px;
}

/* risk cards section */

.pdf-new-report-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 16px;
}

.pdf-new-report-card {
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  margin-bottom: 24px;
}

.pdf-report-card-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.pdf-risk-section {
  background-color: #eceff1;
  border-radius: 8px;
  padding: 20px;
  background-color: #6695a3;
  border: 1px solid #6695a3;
}

.pdf-risk-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}

.pdf-risk-card {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pdf-risk-card-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.pdf-risk-value {
  font-size: 14px;
  font-weight: 500;
}

.pdf-risk-highlight {
  color: white;
  text-align: center;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 14px;
}

.pdf-report-margin-top {
  margin-top: 24px;
}

.pdf-report-risk-container-bg {
  border: 2px solid #ff4b23;
}

.pdf-report-top-risk-container-bg {
  border: 2px solid #167c69;
}

.pdf-report-lab-container-bg {
  border: 2px solid #17748f;
}

.pdf-report-dis-container-bg {
  border: 2px solid #2c768d;
}

/* dowload button */

.download-btn {
  border: 2px solid white;
  outline: none;
  border-radius: 8px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.19);
  height: 30px;
  background-color: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.download-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #ff198c, #ff883e);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 45px;
  right: 45px;
  z-index: 4;
}

.home-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #ff198c, #ff883e);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 45px;
  right: 90px;
  z-index: 4;
}

.download-icon {
  height: 70%;
  transition: all 0.3s ease-in-out;

  pointer-events: none;
}

.home-icon {
  height: 70%;
  transition: all 0.3s ease-in-out;
  fill: white;
  pointer-events: none;
}
