.lifestyle-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .lifestyle-radio-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .lifestyle-card {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .lifestyle-title {
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  .lifestyle-number-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  /* .radio-group select {
    width: 40%;
  }
   */
  .sub-options-container {
    margin-top: 12px;
    padding-left: 16px;
  }
  

  .sub-option-radio {
    display: flex;
    align-items: center;
  }
  
  .unit {
    font-size: 12px;
    color: #666;
    margin-left: 4px;
  }
   
  /* Mobile Styles */
  @media (max-width: 768px) {
    .lifestyle-number-grid {
      grid-template-columns: 1fr;
    }
  
    .lifestyle-card {
      padding: 16px;
    }
  
 
  }

  .inputbox {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    position: relative;
  }
  
  
  .font-size {
    font-size: 20px;
  }
  
  .radio_in_input_component {
    width: 1.3em;
    height: 1.3em;
  }
  
  .radio-button {
    width: 20px;
    height: 20px;
    opacity: 0;
    position: relative;
    right: -20px;
  }
  .c-radio {
    width: 20px;
    height: 20px;
    border: 2px solid #d9d5d5;
    border-radius: 50%;
    pointer-events: none;
    position: relative;
  }
  
  input:checked ~ .c-radio {
    background-color: #675496;
    border: 2px solid #675496;
  }


  .inputbox > label {
    margin-bottom: 10px;
  }

  .tooltip {
    background-color: transparent;
    width: 10px;
    height: 10px;
  
    border: 1px solid rgb(66, 66, 66);
  
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-align: center;
    border-radius: 50%;
    font-size: 6px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: italic;
    margin-left: 5px;
    position: relative;
    top: -4px;
    left: 5px;
  }

  .checkbox-input-box {
    display: flex;
    font-size: 1.5em;
    position: relative;
    display: flex;
    align-items: center;
  }
 
  
  
  .checkbox_box_in_input_component {
    width: 0.7em;
    height: 0.7em;
  }

  .description-bar {
    width: 15px;
    height: 15px;
    /* padding:4px; */
    border: 1px solid black;
    border-radius: 50%;
    background-color: transparent;
    margin-left: 4px;
  }
  
 
  




  