.disclaimer-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
  }
  
  .disclaimer-item {
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 1.5;
  }
  
  .disclaimer-item:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 576px) {
    .disclaimer-item {
      font-size: 14px;
    }
  }
  