/* Sidebar styling */
.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* Width of the sidebar */
  width: 300px;
  height: 100%;
  background-color: white;
  color: black;
  transition: right 0.3s ease;
  z-index: 1000;
  scrollbar-width: none;
  overflow: auto;
  font-size: 14px; /* Adjusted font size for better readability */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar.open {
  right: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Container styling */
.description-container {
  background-color: #f9f9f9;
  padding: 20px; /* Adjusted padding */
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

/* Unordered list styling */
.description-list {
  list-style-type: none;
  font-weight: 500;
  font-size: 14px; /* Adjusted font size */
  padding-left: 10px;
}

.description-list li {
  padding: 5px 0; /* Adjusted padding */
  font-size: 14px;
  font-weight: 500;
}

/* Nested list styling */
.description-list .nested-list {
  list-style-type: none;
  font-weight: 400;
  padding-left: 20px; /* More indentation for clarity */
  margin-top: 5px;
  font-size: 13px; /* Adjusted font size for subpoints */
}

.description-list .nested-list li {
  padding: 3px 0; /* Adjusted padding for nested list items */
  font-size: 13px; /* Adjusted text size for subpoints */
  color: #555; /* Lighter text for distinction */
}

/* Link styling */
.description-link {
  color: #007bff;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: justify;
  font-weight: 500;
  font-size: 14px; /* Adjusted font size */
}

.description-link:hover {
  text-decoration: underline;
}

/* Paragraph styling */
.description-paragraph {
  margin-top: 8px;
  font-size: 14px; /* Adjusted font size */
  text-align: justify;
  font-weight: 500;
}

/* Heading styling */
.description-name {
  text-align: center;
  padding: 8px;
  font-size: 16px; /* Adjusted heading size */
  font-weight: bold;
}

/* Close button styling */
.description-close-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-close-button {
  width: 65px; /* Adjusted button size */
  height: 35px; /* Adjusted button height */
  color: white;
  background-color: #675496;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px; /* Adjusted font size */
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Dark color for better visibility */
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #007bff; /* Change color on hover */
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: 50%; /* Adjusted height for better mobile view */
    bottom: 0;
    right: 0;
    top: auto;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.open {
    transform: translateY(0);
  }

  .description-container {
    padding: 15px; /* Adjusted padding for mobile */
  }

  .description-name {
    font-size: 18px; /* Adjusted heading size for mobile */
  }

  .description-list {
    font-size: 14px; /* Adjusted font size for mobile */
  }

  .description-list .nested-list {
    font-size: 13px; /* Adjusted font size for subpoints on mobile */
  }

  .description-paragraph {
    font-size: 14px; /* Adjusted font size for mobile */
  }
}