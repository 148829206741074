.list-of-scrolling-items {
  width: 97%;
  margin-left: 10%;
  height: 90%;
}

.front-page-container {
  width: 90%;
  height: 100%;
}

.close-button {
  border: none;
  outline: none;
  padding: 12px 16px;
  font-weight: 500;
  margin-bottom: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.popup-text-highlight-text {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  user-select: none;
}

.popup_buttons {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin-top: 35px;
}

.info-button {
  height: 50px;
  padding: 0 30px;
  transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
  border: 0;
  border-radius: 48px;
  background-color: #6442d6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  cursor: pointer;
  margin: 20px;
  color: white;
}

.contentBox {
  /* height: 45%; */
  display: grid;
  grid-gap: 8px;
  /* gap: 15px; */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-flow: row;
  margin-top: 20px;
}



.slider-block-container {
  height: 100%;
}

.leftSection {
  display: flex;
  margin: 0;
  border-radius: 24px;
  background: #f8f1f6;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  height: 95%;
  border-radius: 24px;
}

.left-section-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rightSection {
  position: relative;
  overflow: hidden;
  height: 95%;
  border-radius: 24px;
}

.contentImg-landing {
  width: 100%;
  height: 600px;
  border-radius: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}

.contentBox {
  transition: background-color 0.3s;
}

.get-started-button-container {
  height: 60px;
  padding: 0 30px;
  transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
  border: 0;
  border-radius: 48px;
  background-color: #6442d6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.OpenButton {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.landing-para-container {
  /* height: 90%; */
  display: flex;
  margin-top: 10px;
  /* justify-content: center; */
  /* align-items: center; */
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-down {
  animation: slideDown 1s ease-out forwards;
}

/* Desktop styles */
@media screen and (min-width: 720px) and (max-width: 1300px) {
  /* .slider-block-container {
    height: 550px;
  } */
  .contentBox {
    height: 520px;
  }
  .leftSection {
    height: 500px;
  }
  .rightSection {
    height: 500px;
  }

  .landing-para-container {
    height: 50%;
    display: flex;
    margin-top: 30px;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .landing-para-container {
    height: 50%;
    display: flex;
    margin-top: 30px;
    /* justify-content: center; */
    /* align-items: center; */
  }
}

@media screen and (min-width: 1900px) {
  .leftSection {
    height: 85%;
  }
  .rightSection {
    height: 85%;
  }
  .left-section-content {
    padding: 70px;
  }
  .landing-para-container {
    height: 60%;
    display: flex;
    margin-top: 30px;
    /* justify-content: center; */
    /* align-items: center; */
  }
}

/* Mobile styles */
@media screen and (max-width: 719px) {
  .front-page-container {
    width: 100%;
    height: auto;
    padding: 0 10px;
  }

  .list-of-scrolling-items {
    width: 100%;
    margin-left: 0;
    height: auto;
    padding: 0;
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 15px;
    margin: 0;
    padding: 10px 0;
  }

  .leftSection,
  .rightSection {
    width: 100%;
    /* height: 1000px; */
  }

  .leftSection {
    order: 2; /* Content second on mobile */
    padding: 0;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 24px;
    min-height: auto;
  }

  .rightSection {
    order: 1; /* Image first on mobile */
    margin-bottom: 10px;
  }

  .left-section-content {
    padding: 15px !important;
    width: 100%;
    border-radius: 24px;
  }

  .textTitle {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .textPara {
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 15px;
    max-height: 200px;
    overflow-y: auto;
  }

  .get-started-button-container {
    width: 100%;
    max-width: none;
    height: 45px;
    margin-top: 10px;
    padding: 0 20px;
  }

  /* .slider-block-container {
    height: auto;
  } */

  .contentImg-landing {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Adjust carousel height for mobile */
  .carousel-provider {
    height: 90vh;
  }

  /* Table of content adjustments for mobile */
  .table-of-content {
    margin-top: 20px;
    padding: 0 15px;
  }

  .table-of-content ul {
    padding-left: 20px;
  }

  /* Ensure button is visible */
  .OpenButton {
    font-size: 14px;
    white-space: nowrap;
  }
}
